import { OrderProductItem, OrderShipment, PendingOrderShipment } from '../types/order'

export const filterOrderItemsFuncGenerator =
  (query: string) =>
  ({ productId, name, productComponents, productAlias }: OrderProductItem) =>
    (`#${productId?.toLowerCase() ?? ''}`.indexOf(query) ?? -1) > -1 ||
    ((productAlias?.toLowerCase() ?? '').indexOf(query) ?? -1) > -1 ||
    name.toLowerCase().indexOf(query) > -1 ||
    (productComponents ?? []).some(
      ({ productId: pId, name: nm }) => (`#${pId?.toLowerCase() ?? ''}`.indexOf(query) ?? -1) > -1 || nm.toLowerCase().indexOf(query) > -1
    )

export const filterOrderItems = (items: OrderProductItem[], query: string) => {
  const lowerCasedQuery = query.toLowerCase()

  return items.filter(filterOrderItemsFuncGenerator(lowerCasedQuery)) ?? []
}

export const filterShipments = (
  shipments: (OrderShipment | PendingOrderShipment)[],
  query: string
): (OrderShipment | PendingOrderShipment)[] => {
  const lowerCasedQuery = query.toLowerCase()

  if (!shipments || shipments.length === 0) {
    return []
  }

  return shipments.filter((shipment) => {
    if (!('pendingShipment' in shipment)) {
      // filter on shipment properties
      if (shipment.carrierName && shipment.carrierName.toLowerCase().indexOf(lowerCasedQuery) > -1) {
        return true
      }
      if (shipment.billsOfLading && shipment.billsOfLading.toString().indexOf(lowerCasedQuery) > -1) {
        return true
      }
      if (shipment.carrierMethod && shipment.carrierMethod.toLowerCase().indexOf(lowerCasedQuery) > -1) {
        return true
      }
      if (shipment.trackingNumber && shipment.trackingNumber.indexOf(lowerCasedQuery) > -1) {
        return true
      }

      if (shipment.displayStatus && shipment.displayStatus.toLowerCase().indexOf(lowerCasedQuery) > -1) {
        return true
      }
    }

    // filter on shipment item properties
    if (!shipment.shipmentItems || shipment.shipmentItems.length === 0) {
      return false
    }

    return shipment.shipmentItems.some((shipmentItem) => {
      if (shipmentItem.name && shipmentItem.name.toLowerCase().indexOf(lowerCasedQuery) > -1) {
        return true
      }
      if (shipmentItem.productId && shipmentItem.productId.toLowerCase().indexOf(lowerCasedQuery) > -1) {
        return true
      }
      if (shipmentItem.productAlias && shipmentItem.productAlias.toLowerCase().indexOf(lowerCasedQuery) > -1) {
        return true
      }
      return false
    })
  })
}

export function assignTrackingInfo({
  orderItems,
  shipmentItems,
}: {
  orderItems: OrderProductItem[]
  shipmentItems: OrderShipment[]
}): OrderProductItem[] {
  if (!shipmentItems || shipmentItems.length === 0) {
    return orderItems
  }

  // Create a map to store an array of tracking details for each orderIntLineNo
  const trackingMap = new Map<
    number | string,
    {
      trackingNumber: string
      trackingLink: string
      showTrackingLink: boolean
      carrierMethod: string
      requestTrackingLink: boolean
    }[]
  >()

  // Populate tracking map with orderIntLineNo as the key
  shipmentItems.forEach((shipment) => {
    shipment.shipmentItems?.forEach((item) => {
      if (!item.orderIntLineNo) {
        return
      }
      if (!trackingMap.has(item.orderIntLineNo)) {
        trackingMap.set(item.orderIntLineNo, [])
      }
      trackingMap.get(item.orderIntLineNo)?.push({
        trackingNumber: shipment.trackingNumber,
        trackingLink: shipment.trackingLink,
        showTrackingLink: shipment.showTrackingLink,
        carrierMethod: shipment.carrierMethod,
        requestTrackingLink: shipment.requestTrackingLink,
      })
    })
  })

  // Assign tracking info to orderItems ensuring each item gets its corresponding tracking data
  orderItems = orderItems.map((item, index) => {
    const trackingInfoList = item.orderIntLineNo ? trackingMap.get(item.orderIntLineNo) : undefined
    const trackingInfo = trackingInfoList ? trackingInfoList[index % trackingInfoList.length] : undefined

    return {
      ...item,
      trackingNumber: trackingInfo?.trackingNumber || '',
      trackingLink: trackingInfo?.trackingLink || '',
      showTrackingLink: trackingInfo?.showTrackingLink,
      carrierMethod: trackingInfo?.carrierMethod,
      requestTrackingLink: trackingInfo?.requestTrackingLink,
    }
  })

  return orderItems
}
