import { useEffect, useCallback, lazy, useState } from 'react'
import { Routes, Route, useLocation } from 'react-router-dom'
import { ThemeProvider, CssBaseline, StyledEngineProvider } from '@mui/material'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { useMsal } from '@azure/msal-react'
// import Cookies from 'js-cookie'

import { ErrorBoundary } from './error/ErrorBoundary'
// import Routes from './routes'
import Loadable from './Loadable'

import themes from '../theme'
// import { useAppDispatch, useAppSelector } from '../hooks/reduxHooks'
import { useAppDispatch, useAppSelector } from '../hooks/reduxHooks'
import { selectUiState } from '../stores/UiStore/selectors'
import NavigationScroll from '../layout/NavigationScroll'
// project imports
import MainLayout from '../layout/MainLayout'
import MinimalLayout from '../layout/MinimalLayout'
import { trackPage } from '../utils/segment'
import { isCrossBrandUser, isQorpak, isValidJwt } from '../utils/common'
import { deleteCookie } from '../utils/cookie'
import { selectRole, selectUser } from '../stores/AuthStore/selectors'
import { logoutRequest } from '../authConfig'
import { setIsCrossBrandUser } from '../stores/UserStore/userSlice'
import { UserRole } from '../types/user'

// dashboard routing
const DashboardHome = Loadable(lazy(() => import('../pages/dashboard/Home')))
const DashboardV2 = Loadable(lazy(() => import('../pages/v2/dashboard')))
const OrdersV2 = Loadable(lazy(() => import('../pages/v2/orders')))
const OrderV2 = Loadable(lazy(() => import('../pages/v2/order')))
const Invoices = Loadable(lazy(() => import('../pages/v2/invoices')))
const Notifications = Loadable(lazy(() => import('../pages/v2/notifications')))

// products routing
// const ProductCatalog = Loadable(lazy(() => import('./pages/products/ProductCatalog')))
// const BrowseProducts = Loadable(lazy(() => import('./pages/products/BrowseProducts')))
const ProductList = Loadable(lazy(() => import('../pages/products/ProductList')))
const ProductDetailLite = Loadable(lazy(() => import('../pages/products/ProductDetailLite')))

// B2B cart/checkout routing
const B2BCart = Loadable(lazy(() => import('../pages/b2b/cart/Cart')))
const B2BCheckout = Loadable(lazy(() => import('../pages/b2b/checkout/Checkout')))
const B2BOrderConfirmation = Loadable(lazy(() => import('../pages/b2b/checkout/OrderConfirmation')))

// B2C cart/checkout routing - NOT IN USE
// const B2CCart = Loadable(lazy(() => import('../pages/b2c/cart/Cart')))
// const B2CCheckout = Loadable(lazy(() => import('../pages/b2c/checkout/Checkout')))
// const B2COrderConfirmation = Loadable(lazy(() => import('../pages/b2c/checkout/OrderConfirmation')))

// resources routing
const MyOrders = Loadable(lazy(() => import('../pages/resources/MyOrders')))
const OrderDetail = Loadable(lazy(() => import('../pages/resources/OrderDetail')))

// settings routing
// const Settings = Loadable(lazy(() => import('./pages/system/Settings')))
// const TeamSettings = Loadable(lazy(() => import('./pages/system/TeamSettings')))

// auth routing
const Onboarding = Loadable(lazy(() => import('../pages/auth/Onboarding')))

// New Item Setup App
const NewItemApp = Loadable(lazy(() => import('../pages/new-item-app')))
const NewItemSearch = Loadable(lazy(() => import('../pages/new-item-app/search')))
const NewItemAppFramework = Loadable(lazy(() => import('../pages/new-item-app/FrameWork')))
const NewItemAppItemReview = Loadable(lazy(() => import('../pages/new-item-app/item-review')))

// Logout
const CustomerNewUser = Loadable(lazy(() => import('../components/CustomerNewUser')))

function AuthedContent() {
  const customization = useAppSelector(selectUiState)
  const location = useLocation()
  const { instance } = useMsal()
  const isAuthed = isValidJwt()
  const user = useAppSelector(selectUser)
  const dispatch = useAppDispatch()
  const isProductDetailEnabled =
    user?.userRoles?.includes('Customer Catalog Image Update') ||
    user?.userRoles?.includes('Customer B2B Catalog') ||
    user?.userRoles?.includes('Customer B2B Checkout')
  const isB2BCheckoutEnabled = user?.userRoles?.includes('Customer B2B Checkout')
  const userRole = useAppSelector(selectRole)
  const isEmployee = userRole === UserRole.EMPLOYEE
  const [cookieScript, setCookieScript] = useState(false)
  const [loadedSegmentScript, setLoadedSegmentScript] = useState(false)

  const handleLogout = useCallback(() => {
    deleteCookie(`x-ms-cpim-sso:${process.env.REACT_APP_B2C_TENANT_DOMAIN ?? 'bpkgomnitest.onmicrosoft.com'}_0`)
    deleteCookie(`x-ms-cpim-sso:${process.env.REACT_APP_B2C_TENANT_DOMAIN_QORPAK ?? 'bpkgomnitest.onmicrosoft.com'}_0`)
    instance.logoutRedirect(logoutRequest)
  }, [instance, location])

  const updateDetailCategory = () => (category: Element) => {
    if ((window as any).CookieConsentDialog[`cookieTable${category}Count`] === 0) {
      const element = document.getElementById(`CybotCookiebotDialogDetailBodyContentCookieContainer${category}Card`)
      if (element) element.style.display = 'none'
    }
  }

  useEffect(() => {
    const handleCookiebotDialogDisplay = () => {
      console.log('handleCookiebotDialogDisplay')
      const detailCategories = ['Unclassified']
      const toggleCategories = ['Unclassified']

      detailCategories.forEach(updateDetailCategory)

      for (let i = 0; i < 3; i++) {
        if ((window as any).CookieConsentDialog[`cookieTable${detailCategories[i]}Count`] === 0) {
          const labelElement = document.querySelector(`label[for=CybotCookiebotDialogBodyLevelButton${toggleCategories[i]}]`)
          if (labelElement) {
            const labelEle = labelElement.parentNode as HTMLElement
            labelEle.style.display = 'none'
          }
        }
      }
    }

    const CookieBot = (window as any).Cookiebot
    if (!sessionStorage.getItem('__bp_in')) {
      sessionStorage.setItem('__bp_in', 'true')
      if (CookieBot?.consented && (navigator as any).globalPrivacyControl) {
        CookieBot.submitCustomConsent(false, false, false)
      }
    }

    window.addEventListener('CookiebotOnDialogDisplay', handleCookiebotDialogDisplay, false)

    return () => {
      window.removeEventListener('CookiebotOnDialogDisplay', handleCookiebotDialogDisplay, false)
    }
  }, [instance])

  useEffect(() => {
    const handleOnAccept = () => {
      if ((window as any).Cookiebot.consent.statistics) setLoadedSegmentScript(true)
    }

    window.addEventListener('CookiebotOnAccept', handleOnAccept, false)

    return () => {
      window.removeEventListener('CookiebotOnAccept', handleOnAccept, false)
    }
  }, [])

  useEffect(() => {
    const handleCookiebotOnDecline = () => {
      if (!(window as any).Cookiebot.consent.statistics) setLoadedSegmentScript(false)
    }

    window.addEventListener('CookiebotOnDecline', handleCookiebotOnDecline, false)

    return () => {
      window.removeEventListener('CookiebotOnDecline', handleCookiebotOnDecline, false)
    }
  }, [])

  const CookiebotScript = () => {
    let key = '3753511b-377f-4ac9-a07f-46307a1ba7c6'
    if (isQorpak()) key = '69d56417-4ce9-4218-9d6e-f810129d1e55'
    if (!cookieScript) {
      const script = document.createElement('script')
      script.src = 'https://consent.cookiebot.com/uc.js'
      script.id = 'Cookiebot'
      script.async = true
      script.setAttribute('data-cbid', key)

      script.onload = () => {
        console.log('Cookiebot script loaded.')
        setCookieScript(true)
      }
      document.head.appendChild(script)
    }
  }

  const SegmentScript = () => {
    const segment = document.querySelector('#Segment')
    if (segment) return

    let segmentKey = process.env.REACT_APP_BERLIN_OMNI_SEGMENT_KEY ?? ''
    if (window.location.hostname.includes('qorpak.com')) {
      segmentKey = process.env.REACT_APP_QORPAK_SEGMENT_KEY ?? ''
    }

    const script = document.createElement('script')
    script.id = 'Segment'

    script.textContent = `!function () {
      console.log("Boolean(Cookiebot?.consent?.statistics) >> ", Boolean(Cookiebot?.consent?.statistics))
      var analytics = window.analytics = window.analytics || [];
      if (!analytics.initialize) if (analytics.invoked) window.console && console.error && console.error("Segment snippet included twice.");
      else {
        analytics.invoked = !0; analytics.methods = ["trackSubmit", "trackClick", "trackLink", "trackForm", "pageview", "identify", "reset", "group", "track", "ready", "alias", "debug", "page", "screen", "once", "off", "on", "addSourceMiddleware", "addIntegrationMiddleware", "setAnonymousId", "addDestinationMiddleware", "register"]; analytics.factory = function (e) { return function () { if (window.analytics.initialized) return window.analytics[e].apply(window.analytics, arguments); var i = Array.prototype.slice.call(arguments); if (["track", "screen", "alias", "group", "page", "identify"].indexOf(e) > -1) { var c = document.querySelector("link[rel='canonical']"); i.push({ __t: "bpc", c: c && c.getAttribute("href") || void 0, p: location.pathname, u: location.href, s: location.search, t: document.title, r: document.referrer }) } i.unshift(e); analytics.push(i); return analytics } }; for (var i = 0; i < analytics.methods.length; i++) { var key = analytics.methods[i]; analytics[key] = analytics.factory(key) } analytics.load = function (key, i) { var t = document.createElement("script"); t.type = "text/javascript"; t.async = !0; t.src = "https://cdn.segment.com/analytics.js/v1/" + key + "/analytics.min.js"; var n = document.getElementsByTagName("script")[0]; n.parentNode.insertBefore(t, n); analytics._loadOptions = i };;
        analytics.SNIPPET_VERSION = "5.2.0";

        // configure segment key
        analytics._writeKey = '${segmentKey}';
        analytics.load('${segmentKey}');
        }               
      }();`

    document.head.appendChild(script)
    console.log('Segment script added.')

    trackPage()
  }

  const RemoveSegmentScript = () => {
    const segment = document.querySelector('#Segment')
    if (!segment) return

    document.head.removeChild(segment)
    console.log('Segment script removed.')
  }

  useEffect(() => {
    CookiebotScript()
  }, [location])

  useEffect(() => {
    if (!loadedSegmentScript) {
      RemoveSegmentScript()
      return
    }
    SegmentScript()
  }, [cookieScript, loadedSegmentScript])

  useEffect(() => {
    if (!isAuthed) {
      handleLogout()
    }

    if (isAuthed) {
      dispatch(setIsCrossBrandUser(isCrossBrandUser()))
    }
  }, [isAuthed, handleLogout])

  if (!isAuthed) {
    return null
  }

  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={themes(customization)}>
        <CssBaseline />
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <ErrorBoundary catchErrors="always">
            <NavigationScroll>
              <Routes>
                <Route path="/" element={<MainLayout />}>
                  <Route path="/employee/new-item-app" element={<NewItemApp />} />
                  <Route path="/employee/new-item-app/search" element={<NewItemSearch />} />
                  <Route path="/employee/new-item-app/framework" element={<NewItemAppFramework />} />
                  <Route path="/employee/new-item-app/item-review/:itemId" element={<NewItemAppItemReview />} />
                  {/* <Route element={isProductDetailEnabled}> */}

                  {isProductDetailEnabled && (
                    <>
                      <Route path="/products/list" element={<ProductList />} />
                      <Route path="/products/product/:productId" element={<ProductDetailLite />} />
                      <Route path="/products/productLite/:productId" element={<ProductDetailLite />} />
                    </>
                  )}
                  {/* </Route> */}

                  {isB2BCheckoutEnabled && isProductDetailEnabled && (
                    <>
                      {/* B2B Routes */}
                      <Route path="cart" element={<B2BCart />} />
                      <Route path="order-confirmation" element={<B2BOrderConfirmation />} />
                      <Route path="checkout" element={<B2BCheckout />} />
                      <Route path="checkout/:state" element={<B2BCheckout />} />
                    </>
                  )}
                  {/* B2C Routes - NOT IN USE */}
                  {/* <Route path="cart" element={<B2CCart />} />
                          <Route path="order-confirmation" element={<B2COrderConfirmation />} />
                          <Route path="checkout" element={<B2CCheckout />} />
                          <Route path="checkout/:state" element={<B2CCheckout />} /> */}
                  {/* 
                  <Route path="/products/catalog" element={<ProductCatalog />} />
                  <Route path="/products/browse" element={<BrowseProducts />} /> */}
                  <Route path="/resources/my-orders" element={<MyOrders />} />
                  <Route path="/resources/orders/:orderType" element={<MyOrders />} />
                  <Route path="/newuser" element={<CustomerNewUser />} />

                  {isQorpak() ? (
                    <>
                      <Route path="/dashboard/default" element={<DashboardHome />} />
                      <Route path="/orders" element={<DashboardHome />} />
                      <Route path="/resources/order/:orderId" element={<OrderDetail />} />
                      <Route path="/" element={<DashboardHome />} />
                    </>
                  ) : (
                    <>
                      <Route path="/dashboard/default" element={<DashboardV2 />} />
                      <Route path="/orders" element={<OrdersV2 />} />
                      <Route path="/resources/order/:orderId" element={<OrderV2 />} />
                      {!isEmployee && <Route path="/invoices" element={<Invoices />} />}
                      {!isEmployee && <Route path="/notifications" element={<Notifications />} />}
                      <Route path="/" element={<DashboardV2 />} />
                    </>
                  )}
                </Route>
                <Route path="/" element={<MinimalLayout />}>
                  <Route path="/onboarding" element={<Onboarding />} />
                </Route>
              </Routes>
            </NavigationScroll>
          </ErrorBoundary>
        </LocalizationProvider>
      </ThemeProvider>
    </StyledEngineProvider>
  )
}

export default AuthedContent
