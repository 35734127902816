import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { Entities, FinanceFile } from '../../types/financeFile'

export interface FinanceFilesState {
  FilesList: FinanceFile[]
  Entities: Entities[]
  config?: {
    sort?: 'new2old' | 'old2new'
  }
  financeFileUploadStatus: {
    status: number
    message: string
    processedFileName: string
  }
}
const initialState: FinanceFilesState = {
  FilesList: [],
  Entities: [],
  financeFileUploadStatus: {
    status: 0,
    message: '',
    processedFileName: '',
  },
}

export const financeFilesSlice = createSlice({
  name: 'financeFilesStore',
  initialState,
  reducers: {
    setFinanceFiles: (state, { payload }: PayloadAction<FinanceFile[]>) => {
      state.FilesList = payload
    },
    setEntities: (state, { payload }: PayloadAction<Entities[]>) => {
      state.Entities = payload
    },
    setSort: (state, { payload }: PayloadAction<'new2old' | 'old2new'>) => {
      if (state.config) {
        state.config.sort = payload
      } else {
        state.config = {
          sort: payload,
        }
      }
    },
    setFinanceFileUploadStatus: (state, { payload }: PayloadAction<any>) => {
      state.financeFileUploadStatus = payload
    },
  },
})

export const { setFinanceFiles, setEntities, setSort, setFinanceFileUploadStatus } = financeFilesSlice.actions
export const { reducer } = financeFilesSlice
