export enum UserRole {
  CUSTOMER = 'customer',
  EMPLOYEE = 'employee',
  SUPPLIER = 'supplier',
}

export enum UserStatus {
  Active = 'active',
  Inactive = 'inactive',
}

export enum UserTeamRole {
  Admin = 'admin',
  NonAdmin = 'non-admin',
}

export interface IUser {
  id: string
  firstName: string
  lastName: string
  email: string
  photoUrl?: string
  status: UserStatus
  teamRole: UserTeamRole
}

export interface IUserPayload {
  firstName: string
  lastName: string
  email: string
  status?: UserStatus
  teamRole?: UserTeamRole
  customerId?: string
}

export type Industry = {
  id: string
  name: string
  slug: string
}

export type Interest = {
  id: string
  slug: string
  name: string
}

export type CustomerRole = {
  id: string
  name: string
}

type Customer = {
  id: number
  name: string
}

export type Customers = {
  createdDate: Date
  customerId: string
  customerIds: number[]
  customers?: Customer[]
  email: string
  firstName: string
  id: string
  lastModifiedDate: Date
  lastName: string
  active: boolean
  role: string
  edit: any
  name?: string
}

export type InviteTeamMembersParam = {
  users: {
    email: string
    role: string
  }[]
}

export type CustomerLookups = {
  supportCodes: string[]
  salesReps: string[]
  accountCoordinators: string[]
}
