import { AxiosRequestConfig } from 'axios'

import { HttpClient } from '../services/HttpClient'
import * as AppConfig from '../config'

import type { CustomerRole, Industry, Interest, InviteTeamMembersParam, CustomerLookups } from '../types/user'

export class UserRepository extends HttpClient {
  public getIndustries = async () => {
    const { data } = await this.instance.get<Industry[]>('omni-user/industries')

    return data
  }

  public getCustomerPhoto = async () => {
    const { data } = await this.instance.get('omni-user/photo')
    return data?.imageUrl
  }

  public getInterests = async () => {
    const { data } = await this.instance.get<Interest[]>('omni-user/interests')
    return data
  }

  public getCustomers = async (params: any) => {
    const lastChar = params.slice(-1)
    if (lastChar === ',') {
      params = params.substring(0, params.length - 1)
    }
    const { data } = await this.instance.get(`omni-user/v2/users/customers?${params}`, {
      headers: {
        'Cache-Control': 'no-cache',
      },
    })
    return data
  }

  public createCustomers = async (payload: any) => {
    const { status } = await this.instance.post('omni-user/users/customers', payload)
    return status
  }

  public uploadCustomers = async (payload: File) => {
    const formData = new FormData()
    formData.append(payload.name, payload)
    const { status } = await this.instance.post('omni-user/users/customers/import', formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })
    return status
  }

  public updateCustomers = async (payload: any, id: string) => {
    const { status } = await this.instance.put('omni-user/users/customers/' + id, payload)
    return status
  }

  public uploadCustomerPhoto = async (payload: any) => {
    await this.instance.put('omni-user/photo', payload, { headers: { 'Content-Type': 'multipart/form-data' } })
  }

  public getCustomerRoles = async () => {
    const { data } = await this.instance.get<{ Roles: CustomerRole[] }>('omni-user/customer/roles')
    return data.Roles
  }

  public getUserIndustries = async () => {
    const { data } = await this.instance.get<Industry[]>('omni-user/customer/industries')

    return data
  }

  public getUserInterests = async () => {
    const { data } = await this.instance.get<Interest[]>('omni-user/customer/interests')

    return data
  }

  public inviteTeamMembers = async (payload: InviteTeamMembersParam) => {
    await this.instance.post('omni-user/team/members', payload)
  }

  public updateUserInterests = async (payload: string[]) => {
    await this.instance.post('omni-user/customer/interests', {
      Interests: payload,
    })
  }

  public updateUserIndustries = async (payload: string[]) => {
    await this.instance.post('omni-user/customer/industries', {
      Industries: payload,
    })
  }

  public resetUserPassword = async (payload: string) => {
    await this.instance.put(`omni-user/users/customers/${payload}/password`)
  }

  public getCustomerLookups = async () => {
    const { data } = await this.instance.get<CustomerLookups[]>('omni-user/users/customers/lookups', {
      headers: {
        'Cache-Control': 'no-cache',
      },
    })

    return data
  }

  constructor() {
    super(AppConfig.API_URL)
    this.initializeRequestInterceptor()
  }

  private static classInstance?: UserRepository

  private initializeRequestInterceptor = () => {
    this.instance.interceptors.request.use(this.handleRequest, this.handleError)
  }

  private handleRequest = (config: AxiosRequestConfig) => {
    config.headers!['Ocp-Apim-Subscription-Key'] = AppConfig.APIM_SUBSCRIPTION_KEY

    const token = window.localStorage.getItem('token')

    if (token) {
      config.headers!.Authorization = `Bearer ${token}`
    }

    return config
  }

  public static getInstance() {
    if (!this.classInstance) {
      this.classInstance = new UserRepository()
    }
    return this.classInstance
  }
}
