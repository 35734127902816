import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { UserItem, CustomerGroupedData } from '../../types/userMgmt'
import { CustomerRole, Industry, Interest } from '../../types/user'

export interface IUserState {
  interests: Interest[]
  industries: Industry[]
  roles: CustomerRole[]
  userInterests: string[]
  userIndustries: string[]
  customers: CustomerGroupedData
  addEditResp: {
    type?: string
    status?: number
    data: UserItem
    error: string | null
  }
  uploadCustomerStatus: {
    status: number
    message: string
  }
  isCrossBrandUser: boolean
  resetPasswordResp: {
    resetLoading: boolean
    resetStatus?: number
    resetError: boolean
  }
  customerLookups: {
    supportCodes: string[]
    salesReps: string[]
    accountCoordinators: string[]
  }
}

const initialState: IUserState = {
  industries: [],
  interests: [],
  userInterests: [],
  userIndustries: [],
  roles: [],
  customers: {
    data: [
      {
        firstName: '',
        lastName: '',
        createdDate: new Date(),
        email: '',
        customerIds: [],
        lastModifiedDate: new Date(),
        active: false,
        id: '',
        role: '',
        edit: '',
        customerId: '',
        customers: [
          {
            id: 0,
            name: '',
            accountCoordinator: '',
            salesRep: '',
            supportTeamCode: '',
          },
        ],
        userGroups: [{ id: '', name: '' }],
        brand: '1',
      },
    ],
    nonImplementedFields: [],
    pagination: {
      currentPage: 1,
      pages: 1,
      rowsPerPage: 1,
      total: 1,
    },
  },
  addEditResp: {
    type: '',
    status: 0,
    data: {
      firstName: '',
      lastName: '',
      createdDate: new Date(),
      email: '',
      customerIds: [],
      lastModifiedDate: new Date(),
      active: false,
      id: '',
      role: '',
      edit: '',
      customerId: '',
      customers: [
        {
          id: 0,
          name: '',
          accountCoordinator: '',
          salesRep: '',
          supportTeamCode: '',
        },
      ],
      userGroups: [{ id: '', name: '' }],
    },
    error: null,
  },
  uploadCustomerStatus: {
    status: 0,
    message: '',
  },
  isCrossBrandUser: false,
  resetPasswordResp: {
    resetLoading: false,
    resetError: false,
    resetStatus: 0,
  },
  customerLookups: {
    supportCodes: [],
    salesReps: [],
    accountCoordinators: [],
  },
}

export const userSlice = createSlice({
  name: 'userStore',
  initialState,
  reducers: {
    setIndustries: (state, { payload }: PayloadAction<Industry[]>) => {
      state.industries = payload
    },
    setInterests: (state, { payload }: PayloadAction<Interest[]>) => {
      state.interests = payload
    },
    setUserIndustries: (state, { payload }: PayloadAction<string[]>) => {
      state.userIndustries = payload
    },
    setUserInterests: (state, { payload }: PayloadAction<string[]>) => {
      state.userInterests = payload
    },
    setCustomerRoles: (state, { payload }: PayloadAction<CustomerRole[]>) => {
      state.roles = payload
    },
    setCustomers: (state, { payload }: PayloadAction<CustomerGroupedData>) => {
      state.customers = payload
    },
    setUploadCustomerStatus: (state, { payload }: PayloadAction<any>) => {
      state.uploadCustomerStatus = payload
    },
    setAddEditResp: (state, { payload }: PayloadAction<any>) => {
      state.addEditResp = payload
    },
    setIsCrossBrandUser: (state, { payload }: PayloadAction<boolean>) => {
      state.isCrossBrandUser = payload
    },
    setResetPasswordResp: (state, { payload }: PayloadAction<any>) => {
      state.resetPasswordResp = payload
    },
    setCustomerLookups: (state, { payload }: PayloadAction<any>) => {
      state.customerLookups = payload
    },
  },
})

export const {
  setCustomerRoles,
  setIndustries,
  setInterests,
  setUserIndustries,
  setUserInterests,
  setCustomers,
  setAddEditResp,
  setUploadCustomerStatus,
  setIsCrossBrandUser,
  setResetPasswordResp,
  setCustomerLookups,
} = userSlice.actions
export const { reducer } = userSlice
