import type { RootState } from '../rootReducer'

export const selectIndustries = ({ userReducer }: RootState) => userReducer.industries

export const selectInterests = ({ userReducer }: RootState) => userReducer.interests

export const selectUserIndustries = ({ userReducer }: RootState) => userReducer.userIndustries

export const selectUserInterests = ({ userReducer }: RootState) => userReducer.userInterests

export const selectCustomerRoles = ({ userReducer }: RootState) => userReducer.roles

export const selectCustomers = ({ userReducer }: RootState) => userReducer.customers

export const addEditResp = ({ userReducer }: RootState) => userReducer.addEditResp

export const selectUploadCustomerStatus = ({ userReducer }: RootState) => userReducer.uploadCustomerStatus

export const selectUserBrand = ({ userReducer }: RootState) => userReducer.customers.data[0]?.brand

export const selectIsCrossBrandUser = ({ userReducer }: RootState) => userReducer.isCrossBrandUser

export const selectResetUserPassword = ({ userReducer }: RootState) => userReducer.resetPasswordResp
