const ClipboardDollarIcon = ({ color = '#020617' }: { color?: string }) => (
  <svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_1378_3308)">
      <path
        d="M7.49996 4.66675H5.83329C5.39127 4.66675 4.96734 4.84234 4.65478 5.1549C4.34222 5.46746 4.16663 5.89139 4.16663 6.33341V16.3334C4.16663 16.7754 4.34222 17.1994 4.65478 17.5119C4.96734 17.8245 5.39127 18.0001 5.83329 18.0001H14.1666C14.6087 18.0001 15.0326 17.8245 15.3451 17.5119C15.6577 17.1994 15.8333 16.7754 15.8333 16.3334V6.33341C15.8333 5.89139 15.6577 5.46746 15.3451 5.1549C15.0326 4.84234 14.6087 4.66675 14.1666 4.66675H12.5"
        stroke={color}
        strokeWidth="1.25"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7.5 4.66667C7.5 4.22464 7.6756 3.80072 7.98816 3.48816C8.30072 3.17559 8.72464 3 9.16667 3H10.8333C11.2754 3 11.6993 3.17559 12.0118 3.48816C12.3244 3.80072 12.5 4.22464 12.5 4.66667C12.5 5.10869 12.3244 5.53262 12.0118 5.84518C11.6993 6.15774 11.2754 6.33333 10.8333 6.33333H9.16667C8.72464 6.33333 8.30072 6.15774 7.98816 5.84518C7.6756 5.53262 7.5 5.10869 7.5 4.66667Z"
        stroke={color}
        strokeWidth="1.25"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11.6667 9.66675H9.58337C9.25185 9.66675 8.93391 9.79844 8.69949 10.0329C8.46507 10.2673 8.33337 10.5852 8.33337 10.9167C8.33337 11.2483 8.46507 11.5662 8.69949 11.8006C8.93391 12.0351 9.25185 12.1667 9.58337 12.1667H10.4167C10.7482 12.1667 11.0662 12.2984 11.3006 12.5329C11.535 12.7673 11.6667 13.0852 11.6667 13.4167C11.6667 13.7483 11.535 14.0662 11.3006 14.3006C11.0662 14.5351 10.7482 14.6667 10.4167 14.6667H8.33337"
        stroke={color}
        strokeWidth="1.25"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="M10 14.6666V15.4999M10 8.83325V9.66659" stroke={color} strokeWidth="1.25" strokeLinecap="round" strokeLinejoin="round" />
    </g>
    <defs>
      <clipPath id="clip0_1378_3308">
        <rect width="20" height="20" fill="white" transform="translate(0 0.5)" />
      </clipPath>
    </defs>
  </svg>
)

export { ClipboardDollarIcon }
