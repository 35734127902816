import { AxiosRequestConfig } from 'axios'
import { HttpClient } from '../services/HttpClient'
import * as AppConfig from '../config'
import { IFacetField, ProductDetail } from '../types/product'
import { UserRole } from '../types/user'
// import { mockData } from './ProductMock'
import { IProductSimple, ProductSearchQueryParams } from '../types/product'
import { isQorpak } from '../utils/common'

export class ProductRepository extends HttpClient {
  private userType?: UserRole | null

  constructor(userType?: UserRole | null) {
    super(AppConfig.API_URL)
    this.initializeRequestInterceptor()
    this.userType = userType
  }

  public getProducts = async ({
    query,
    userIdentifier,
    filterQuery,
    sortQuery,
    page,
    pageLimit,
    searchType = 1,
  }: ProductSearchQueryParams) => {
    const params = new URLSearchParams({
      query,
      url: window.location.href ?? '',
      userIdentifier: userIdentifier,
      searchType: searchType.toString(),
      sort: sortQuery?.toString() ? `in_stock+desc,${sortQuery.toString()}` : 'in_stock+desc',
      // filterQuery: filterQuery ?? '',
      page: page.toString(),
      pageLimit: pageLimit.toString(),
      referrerUrl: document?.referrer ?? '',
    })

    if (filterQuery && filterQuery.length > 0) {
      filterQuery.forEach((filter) => {
        params.append('filterQuery', filter)
      })
    }
    if (!query) {
      params.delete('query')
      params.delete('searchType')
    }

    const { data } = await this.instance.get<{
      items: IProductSimple[]
      metadata: { pagination: { total: number } }
      facetFields: Record<string, IFacetField[]>
    }>(`omni-catalog/v2/catalog/customer/search/berlin?${params.toString()}`, {
      headers: {
        'Cache-Control': 'no-cache',
      },
    })
    return {
      products: data.items,
      total: data.metadata.pagination.total,
      facetFields: data.facetFields,
      hasFilterQuery: !!(filterQuery && filterQuery.length > 0),
    }
  }

  public uploadProductImage = async (id: number, img: File) => {
    const formData = new FormData()
    formData.append('image', img)
    const { data } = await this.instance.post(
      `omni-catalog/catalog/${this.userType === UserRole.EMPLOYEE ? 'employee' : 'customer'}/products/${id}/image`,
      formData,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      }
    )

    return data?.data || undefined
  }

  public downloadInventoryReport = async () => {
    const { data } = await this.instance.get<any>('omni-catalog/catalog/customer/inventoryreport', {
      headers: {
        'Cache-Control': 'no-cache',
        'Content-Type': 'text/csv',
        'Content-Disposition': `filename=InventoryReport_${Date.now()}.csv`,
      },
    })
    return data
  }

  public getProduct = async (id: string) => {
    // return mockData.items.find((item) => item.id === id) as ProductDetail
    console.log('id', id)
    const { data } = await this.instance.get<{ product: ProductDetail }>(
      `omni-catalog/${this.userType === UserRole.EMPLOYEE ? 'catalog/employee' : 'v2/catalog/customer'}/products/${id}`,
      {
        headers: {
          'Cache-Control': 'no-cache',
        },
      }
    )
    return data?.product || undefined
  }

  private static classInstance?: ProductRepository

  private initializeRequestInterceptor = () => {
    this.instance.interceptors.request.use(this.handleRequest, this.handleError)
  }

  private handleRequest = (config: AxiosRequestConfig) => {
    config.headers!['Ocp-Apim-Subscription-Key'] = AppConfig.APIM_SUBSCRIPTION_KEY

    const token = window.localStorage.getItem('token')

    if (token) {
      config.headers!.Authorization = `Bearer ${token}`
    }

    if (window.location.hostname === 'localhost') {
      config.headers!['requesting-domain'] = isQorpak() ? 'https://dev.portal.qorpak.com/' : 'https://dev.portal.berlinpackaging.com/'
    }

    return config
  }

  public static getInstance(userType?: UserRole | null) {
    if (!this.classInstance) {
      this.classInstance = new ProductRepository(userType)
    }
    return this.classInstance
  }
}
