import { setProduct, setProducts, setPLPLoader, resetProducts, setUploadedImage, setInventoryReportData } from './productsSlice'

import { trackProductAction } from '../../utils/segment'
import { AppThunk } from '../rootStore'
import { addRequest, removeRequest } from '../LoadingStore/loadingSlice'
import { Requests } from '../../types/common'
import { ProductRepository } from '../../repositories/ProductRepository'
import { ProductSearchQueryParams } from '../../types/product'

export const getProducts =
  (params: ProductSearchQueryParams): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(resetProducts({ skipClearFacetFields: !!params.filterQuery }))

      const productRepository = ProductRepository.getInstance()

      dispatch(addRequest(Requests.getProducts))
      dispatch(setPLPLoader(true))

      const { products, total, facetFields, hasFilterQuery } = await productRepository.getProducts(params)
      dispatch(setProducts({ products, total, facetFields, hasFilterQuery }))
      dispatch(setPLPLoader(false))
    } catch (err) {
      console.log('[getproducts.error]', err)
    } finally {
      dispatch(setPLPLoader(false))
      dispatch(removeRequest(Requests.getProducts))
    }
  }

export const getProduct =
  (id: string): AppThunk =>
  async (dispatch, getState) => {
    try {
      const { authReducer } = getState()
      const productRepository = ProductRepository.getInstance(authReducer.role)

      dispatch(addRequest(Requests.getProductDetails))
      const product = await productRepository.getProduct(id)

      dispatch(setProduct(product))
      trackProductAction('Product Viewed', product)
    } catch (err) {
      console.log('[getproduct.error]', err)
      dispatch(setProduct(undefined))
    } finally {
      dispatch(removeRequest(Requests.getProductDetails))
    }
  }

export const downloadInventoryReport = (): AppThunk => async (dispatch, getState) => {
  try {
    const { authReducer } = getState()
    const productRepository = ProductRepository.getInstance(authReducer.role)
    dispatch(setPLPLoader(true))
    dispatch(addRequest(Requests.downloadInventoryReport))
    const report = await productRepository.downloadInventoryReport()
    dispatch(setInventoryReportData(report))
  } catch (err) {
    console.log('[getproduct.error]', err)
    dispatch(setPLPLoader(false))
    dispatch(setInventoryReportData(undefined))
  } finally {
    dispatch(setPLPLoader(false))
    dispatch(removeRequest(Requests.downloadInventoryReport))
  }
}

export const uploadProductImage =
  (id: number, sku: string, page: string, img: File): AppThunk =>
  async (dispatch, getState) => {
    try {
      const { authReducer } = getState()
      const productRepository = ProductRepository.getInstance(authReducer.role)
      dispatch(addRequest(Requests.uploadProductImage))
      const uploadedImg = await productRepository.uploadProductImage(id, img)
      // future reference
      console.log('page ', page + sku)
      dispatch(removeRequest(Requests.uploadProductImage))
      if (uploadedImg?.url) {
        dispatch(setUploadedImage({ url: uploadedImg?.url }))
      } else {
        dispatch(setUploadedImage({ url: '', error: 'something went wrong. please try again' }))
      }
    } catch (err) {
      dispatch(setUploadedImage({ url: '', error: 'something went wrong. please try again' }))
      console.log('[uploadProductImage.error]', err)
    } finally {
      dispatch(removeRequest(Requests.uploadProductImage))
    }
  }
