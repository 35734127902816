import { IIncomingInventoryItem, IProductSimple, PriceTier, UnitOfMeasureData } from '../types/product'
import numeral from 'numeral'

export const EXCLUDED_PRODUCT_IDS: { [key: string]: boolean } = {
  'DELIVERY-OUT': true,
  'PALLET-GLOBAL': true,
  'INBOUND-FREIGHT': true,
  'PALLETS-WHSE': true,
  'DELIVERY-OUT-DS': true,
  'PALLETS-DS': true,
  'AMCOR-PLASTIC-PLT': true,
  'ARTWORK-DS': true,
  ARTWORK_REVISION: true,
  'BALL-PALLET': true,
  'BALL-TIERSHEET': true,
  'BALL-TOPFRAME': true,
  'BERT-SA': true,
  'BPO-PALLETS-WHSE': true,
  'BRUNI-PALLET': true,
  'BRUNI-TIER-SHEET': true,
  'BULK-PALLET-WHSE': true,
  'CANWORKS-PALLET': true,
  CERT_OF_COMPLIANCE: true,
  CHANGEOVER_FEE: true,
  'CKS-BULK-PALLET': true,
  'CKS-BULK-PALLET-DS': true,
  'COLOR-PURGE-CHARGE': true,
  CORNERBOARD: true,
  'CORRECT-DS': true,
  'CORRECT-WH': true,
  'COW-PALLET-W40X48': true,
  'CROWN-PLASTIC-PLT': true,
  'CROWN-TIERSHEET': true,
  'CROWN-TOPFRAME': true,
  CUTTING_DIE: true,
  'DELIVERY-PEND': true,
  DEL_BERLIN_TRUCK: true,
  DESIGN: true,
  'ENERGY-SURCHARGE': true,
  'FILM-CHARGE-DS': true,
  'FILM-CHARGE-WH': true,
  'FILM-REVISION-DS': true,
  'FILM-REVISION-WH': true,
  FLAME_TREAT: true,
  'FOTE-PALLET': true,
  FUEL_SURCHARGE: true,
  'LA-PLASTIC-PALLET': true,
  LABEL_DEVELOPMENT: true,
  'LG-POUCH-BOX': true,
  LINER_CHARGE: true,
  'MCC-TIERSHEET': true,
  'MCC-TOPFRAME': true,
  MCCPALLET: true,
  'MISC-CHARGE': true,
  'MISC-CHARGE-DS': true,
  MISC_SAMPLE_BUCKET: true,
  'MODEL/PROTOTYPE': true,
  'OI-TIERSHEET': true,
  'OI-TOPFRAME': true,
  'PALLET-AMCOR-WHSE': true,
  'PALLET-BALL-ENDS': true,
  'PALLET-BALL-FH12': true,
  'PALLET-BALL-FH16': true,
  'PALLET-BHW16-ICBD': true,
  'PALLET-BULK-DS': true,
  'PALLET-BULK-WHSE': true,
  'PALLET-CROWN-12OZ': true,
  'PALLET-CROWN-16OZ': true,
  'PALLET-DVP-GBLBULK': true,
  'PALLET-HT-SARTEN': true,
  'PALLET-MTL': true,
  'PALLET-MTL-EUR': true,
  'PALLET-MTL-NC': true,
  'PALLET-O-I': true,
  'PALLET-OVERSIZED': true,
  'PALLET-PLAS-CBRD': true,
  'PALLET-SILGAN/AVOA': true,
  'PALLET-WHSE-OLYMP': true,
  'PALLET-WOOD-CBRD': true,
  'PALLET-WOOD-IHC': true,
  'PALLET-WOOD-NC': true,
  'PALLET-ZEP': true,
  'PALLETIZATION-LF': true,
  'PALLETS-BERLIN': true,
  'PALLETS-DUNNAGE': true,
  'PALLETS-DUNNAGE-DS': true,
  'PALLETS-GLASS': true,
  'PALLETS-GMA-1': true,
  'PALLETS-GMA-2': true,
  'PALLETS-HT': true,
  'PALLETS-NEW-HT': true,
  'PALLETS-SEMILLS': true,
  'PALLETS-SILGAN': true,
  'PALLETS-WH-US': true,
  'PALLETS-WHSE-CAN': true,
  'PITT-NON-INV-SAMPL': true,
  'PITT-PALLETS-HT': true,
  'PITT-PALLETS-PLSTC': true,
  'PITT-PALLETS-WHSE': true,
  PLATE_CHARGES: true,
  'PRINTING-FEE': true,
  PRINT_PLATE: true,
  REBATE: true,
  REPACK: true,
  'RESIN-SURCHARGE': true,
  RESTOCK: true,
  'RETURN-PALLET-BALL': true,
  'RETURN-PALLET-OI': true,
  'SA-BULK-PALLET': true,
  'SA-DRUM-PALLET-HT': true,
  'SA-PALLET': true,
  'SA-PALLETS-HT': true,
  SAMPLE: true,
  SCREEN_CHARGE: true,
  'SMALL-ORDER-CHARGE': true,
  STERILIZATION: true,
  STORAGE: true,
  'TARIFF-CHARGE-DS': true,
  'TARIFF-CHARGE-WHSE': true,
  TESTING_CHARGES: true,
  'TIER-SHEET-DS': true,
  'TIER-SHEET-WHSE': true,
  TOOLING: true,
  'TOP-FRAME': true,
  'TOP-FRAME-WHSE': true,
  'TOP-FRAMES-DS': true,
  'TOP-FRAMES-WHSE': true,
  TRANSERFEE: true,
  TRANS_SURCHARGE: true,
  'VOBEV-PALLET': true,
}

export const getFormattedProductPrice = (
  product: IProductSimple
): Record<string, (PriceTier & { salePrice?: number; hasConflictingPriceTiers?: boolean })[]> => {
  const pricing: Record<string, (PriceTier & { hasConflictingPriceTiers?: boolean })[]> = {}
  const variantsPriceTiers = product.variants?.reduce<(PriceTier & { hasConflictingPriceTiers?: boolean })[]>((acc, variants) => {
    if (variants.pricing?.priceTiers) {
      acc.push(
        ...variants.pricing.priceTiers.map((priceTier: PriceTier & { hasConflictingPriceTiers?: boolean }) => ({
          ...priceTier,
          hasConflictingPriceTiers: variants.hasConflictingPriceTiers,
          salePrice: variants.salePrice,
        }))
      )
    }
    return acc
  }, [])
  const pricingPriceTiers = product.properties?.pricing?.priceTiers ?? []

  let priceTiers = variantsPriceTiers

  // add fallback to price tiers from pricing object to not
  // break pricing while variants pricing is not returned
  if (priceTiers.length === 0) {
    priceTiers = pricingPriceTiers
  }
  priceTiers.forEach((priceTier) => {
    if (priceTier.unitOfMeasure in pricing) {
      pricing[priceTier.unitOfMeasure] = [...pricing[priceTier.unitOfMeasure], priceTier]
    } else {
      pricing[priceTier.unitOfMeasure] = [priceTier]
    }
  })
  return pricing
}

export const getTotalIncomingInventories = (incomingInventories?: IIncomingInventoryItem[]): number => {
  if (!incomingInventories) {
    return 0
  }
  return incomingInventories.reduce((acc, curr) => {
    acc += curr.quantity
    return acc
  }, 0)
}

export const formatQuantity = (quantity: number, uomData: UnitOfMeasureData[], selectedUom: string | null | undefined): string => {
  const selectedUomData = uomData.find((uom) => uom.uom === selectedUom)
  if (selectedUomData) {
    const uomQuantity = selectedUomData.quantity ?? 1
    return `${quantity.toLocaleString('en-US')} (${Math.floor(quantity / uomQuantity).toLocaleString('en-US')} ${selectedUomData.uom})`
  }
  return `${quantity.toLocaleString('en-US')}`
}

export const getFormattedQuantity = (
  rowQuantity: number,
  uomData: UnitOfMeasureData[],
  selectedUom: string | null | undefined,
  config?: { withoutUom?: boolean }
): string => {
  if (config?.withoutUom) {
    return `${(rowQuantity ?? 0).toLocaleString('en-US')}`
  }
  if (uomData) {
    const selectedUomData = uomData.find((uom) => uom.uom === selectedUom)
    if (selectedUomData?.quantity && selectedUomData.uom && rowQuantity) {
      return `${Math.floor(rowQuantity / selectedUomData.quantity).toLocaleString('en-US')} ${selectedUomData?.uom?.toUpperCase()}`
    }
  }
  return `${(rowQuantity ?? 0).toLocaleString('en-US')}`
}
export type PriceWithSale =
  | {
      from: string
      salePrice?: string
    }
  | { fixed: string; salePrice?: string }
  | { note: string }
  | null

export const getPriceWithSale = (product: IProductSimple, selectedUom: string): PriceWithSale => {
  const pricingFormat = '$0,0.0000'
  if (product && selectedUom) {
    const pricing = getFormattedProductPrice(product)
    if (selectedUom in pricing) {
      const uomPrice = pricing[selectedUom]
      if (uomPrice.length > 1) {
        const lowestPrice = Math.min(...uomPrice.map((p) => p.price))

        if (uomPrice.findIndex((price) => price.hasInvalidPrice === true || price.hasConflictingPriceTiers === true) >= 0) {
          return { note: 'Pricing to be confirmed.' }
        }

        const lowestSalePrice = Math.min(...uomPrice.map((p) => p.salePrice ?? 0))
        if (lowestSalePrice > 0 && lowestSalePrice < lowestPrice) {
          return { from: numeral(lowestPrice).format(pricingFormat), salePrice: numeral(lowestSalePrice).format(pricingFormat) }
        } else {
          return { from: numeral(lowestPrice).format(pricingFormat) }
        }
      } else {
        if (uomPrice[0].hasInvalidPrice || uomPrice[0].hasConflictingPriceTiers) {
          return { note: 'Pricing to be confirmed.' }
        }
        if (uomPrice[0].salePrice && uomPrice[0].salePrice > 0 && uomPrice[0].salePrice < uomPrice[0].price) {
          return {
            fixed: numeral(uomPrice[0].price).format(pricingFormat),
            salePrice: numeral(uomPrice[0].salePrice).format(pricingFormat),
          }
        } else {
          return { fixed: numeral(uomPrice[0].price).format(pricingFormat) }
        }
      }
    }
  }
  return null
}

export const extractProductSkuFromProductId = (productId: string) => {
  if (productId.includes('-')) {
    return productId.split('-')[0]
  }
  return productId
}

export const extractProductSkuFromProductName = (name: string) => {
  const filterStr = /\(([^)]+)\)/
  return filterStr.exec(name)?.[1]
}

export const extractProductSku = (productName: string, productId?: string): string => {
  if (productId && EXCLUDED_PRODUCT_IDS[productId]) {
    return ''
  }

  if (productName) {
    const sku = extractProductSkuFromProductName(productName)
    if (sku) {
      return sku
    }
  }

  if (productId) {
    return extractProductSkuFromProductId(productId)
  }

  return ''
}
