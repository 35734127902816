import { useEffect, useCallback, lazy } from 'react'
import { Routes, Route, useLocation } from 'react-router-dom'
import { ThemeProvider, CssBaseline, StyledEngineProvider } from '@mui/material'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'

import { ErrorBoundary } from '../components/error/ErrorBoundary'
import Loadable from '../components/Loadable'

// import Routes from './routes'
import themes from '../theme'
import { useAppSelector, useAppDispatch } from '../hooks/reduxHooks'
import { selectUiState } from '../stores/UiStore/selectors'
import { setLogoutInProgress, setRole } from '../stores/AuthStore/authSlice'
import NavigationScroll from '../layout/NavigationScroll'

// project imports
import MainLayout from '../layout/MainLayout'
import MinimalLayout from '../layout/MinimalLayout'
import { trackPage } from '../utils/segment'
import { isValidJwt } from '../utils/common'
import { selectUser } from '../stores/AuthStore/selectors'

// dashboard routing
const DashboardHome = Loadable(lazy(() => import('../pages/employee/dashboard')))

const DashboardUserMgmt = Loadable(lazy(() => import('../pages/employee/user-management')))
// resources routing
const OrderDetail = Loadable(lazy(() => import('../pages/employee/order-detail')))
// New Item Setup App
const NewItemApp = Loadable(lazy(() => import('../pages/new-item-app')))
const NewItemAppFramework = Loadable(lazy(() => import('../pages/new-item-app/FrameWork')))
const NewItemAppItemReview = Loadable(lazy(() => import('../pages/new-item-app/item-review')))
const NewItemSearch = Loadable(lazy(() => import('../pages/new-item-app/search')))

// product routing
const ProductDetail = Loadable(lazy(() => import('../pages/products/ProductDetail')))

const Finance = Loadable(lazy(() => import('../pages/employee/finance')))

function EmployeeRoutes() {
  const dispatch = useAppDispatch()
  const customization = useAppSelector(selectUiState)
  const isAuthed = isValidJwt()
  const location = useLocation()
  const user = useAppSelector(selectUser)
  const isProductDetailEnabled =
    user?.userRoles?.includes('Customer Catalog Image Update') ||
    user?.userRoles?.includes('Customer B2B Catalog') ||
    user?.userRoles?.includes('Customer B2B Checkout')
  const isFinanceEnabled = user?.userRoles?.includes('Employee Finance')
  const isUserManagementEnabled = user?.userRoles?.includes('Employee User-Management')

  const handleLogout = useCallback(() => {
    dispatch(setRole(null))
    dispatch(setLogoutInProgress(true))
    window.localStorage.removeItem('token')
    window.location.href = 'https://berlin.okta.com'
  }, [location, dispatch])

  useEffect(() => {
    trackPage()
  }, [location])

  useEffect(() => {
    if (!isAuthed) {
      handleLogout()
    }
  }, [isAuthed, handleLogout])

  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={themes(customization)}>
        <CssBaseline />
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <ErrorBoundary catchErrors="always">
            <NavigationScroll>
              <Routes>
                <Route path="/" element={<MainLayout />}>
                  <Route path="/dashboard/default" element={<DashboardHome />} />
                  <Route path="/resources/order/:orderId" element={<OrderDetail />} />
                  {isFinanceEnabled && <Route path="/employee/finance" element={<Finance />} />}
                  {isUserManagementEnabled && <Route path="/employee/user-management" element={<DashboardUserMgmt />} />}
                  <Route path="/employee/new-item-app" element={<NewItemApp />} />
                  <Route path="/employee/new-item-app/framework" element={<NewItemAppFramework />} />
                  <Route path="/employee/new-item-app/search" element={<NewItemSearch />} />
                  <Route path="/employee/new-item-app/item-review/:itemId" element={<NewItemAppItemReview />} />
                  {isProductDetailEnabled && (
                    <>
                      <Route path="/products/product/:productId" element={<ProductDetail />} />
                    </>
                  )}
                  <Route path="/" element={<DashboardHome />} />
                </Route>
                <Route path="/" element={<MinimalLayout />}></Route>
              </Routes>
            </NavigationScroll>
          </ErrorBoundary>
        </LocalizationProvider>
      </ThemeProvider>
    </StyledEngineProvider>
  )
}

export default EmployeeRoutes
