import React from 'react'
import { Button, Drawer, IconButton, SvgIcon, Typography, Link, useTheme, Divider, Box } from '@mui/material'
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos'
import { PersonAddAlt } from '@mui/icons-material'

import { UserRole } from '../../../../types/user'
import { useAppDispatch, useAppSelector } from '../../../../hooks/reduxHooks'
import { selectRole, selectUser } from '../../../../stores/AuthStore/selectors'
import { BoxIcon, CatalogIcon, ClipboardDollarIcon, InvoiceIcon, NotificationIcon } from '../../../../components/icons'
import { selectIsCrossBrandUser } from '../../../../stores/UserStore/selectors'
import { isQorpak } from '../../../../utils/common'
import { reset } from '../../../../stores/InvoicesStore/invoicesSlice'
import { trackNavigationMenu } from '../../../../utils/segment'

const DRAWER_WIDTH = '325px'

type Props = {
  isOpen: boolean
  setIsOpen: (isOpen: boolean) => void
  children?: React.ReactNode
  handleLogout?: () => void
  shouldDisplayCatalog?: boolean
  catalogHref?: string
  iconColor: string | undefined
}

const MobileMenu = ({
  isOpen,
  setIsOpen,
  children,
  handleLogout,
  shouldDisplayCatalog = false,
  catalogHref,
  iconColor,
}: Props): React.ReactElement | null => {
  const theme = useTheme()
  const dispatch = useAppDispatch()
  const user = useAppSelector(selectUser)
  const userRole = useAppSelector(selectRole)
  const isCrossBrandUser = useAppSelector(selectIsCrossBrandUser)
  const isEmployee = userRole === UserRole.EMPLOYEE
  const isFinanceEnabled = user?.userRoles?.includes('Employee Finance')
  const isUserManagementEnabled = user?.userRoles?.includes('Employee User-Management')

  const menuOptStyle = {
    textDecoration: 'none',
    color: theme.palette.common.black,
    display: 'flex',
    width: '100%',
    justifyContent: 'space-between',
    padding: '16px 24px',
    '&:hover,&:focus': {
      color: theme.palette.common.primaryBlue,
      background: 'transparent',
    },
  }

  const menuOptTextStyle = {
    '&.MuiTypography-root': {
      padding: '0',
      marginLeft: '0',
      fontWeight: '700',
      fontSize: '20px',
    },
  }

  return (
    <Drawer
      sx={{
        flexShrink: 0,
        '& .MuiDrawer-paper': {
          width: DRAWER_WIDTH,
          boxSizing: 'border-box',
          alignItems: 'flex-start',
          padding: '20px 0',
        },
        display: 'none',
        [theme.breakpoints.down(800)]: {
          display: 'flex',
        },
      }}
      variant="persistent"
      anchor="right"
      open={isOpen}
    >
      {/* Close Icon  */}
      <IconButton onClick={() => setIsOpen(!isOpen)} sx={{ alignSelf: 'flex-end' }}>
        <SvgIcon>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            strokeWidth="1.5"
            stroke="currentColor"
            fill="none"
            strokeLinecap="round"
            strokeLinejoin="round"
          >
            <path stroke="none" d="M0 0h24v24H0z" fill="none" />
            <path d="M18 6l-12 12" />
            <path d="M6 6l12 12" />
          </svg>
        </SvgIcon>
      </IconButton>

      {/* Rest of Options  */}
      <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', width: '100%', height: '100%' }}>
        <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', width: '100%' }}>
          {children}
          {!isCrossBrandUser && (
            <Box sx={{ display: 'flex', flexDirection: 'column' }}>
              {isEmployee ? (
                <>
                  <Link
                    href={'/'}
                    sx={menuOptStyle}
                    onClick={() => {
                      trackNavigationMenu('Orders')
                    }}
                  >
                    <span style={{ display: 'flex', columnGap: '20px', alignItems: 'center' }}>
                      <BoxIcon color={iconColor} />
                      <Typography sx={menuOptTextStyle}>Orders</Typography>
                    </span>
                  </Link>
                  <Divider />
                  {isUserManagementEnabled && (
                    <Link
                      href={'/employee/user-management'}
                      sx={menuOptStyle}
                      onClick={() => {
                        trackNavigationMenu('User Management')
                      }}
                    >
                      <span style={{ display: 'flex', columnGap: '20px', alignItems: 'center' }}>
                        <PersonAddAlt sx={{ color: iconColor }} />
                        <Typography sx={menuOptTextStyle}>User Management</Typography>
                      </span>
                    </Link>
                  )}
                  <Divider />
                  {isFinanceEnabled && (
                    <>
                      <Link
                        href={'/employee/finance'}
                        sx={menuOptStyle}
                        onClick={() => {
                          trackNavigationMenu('Finance')
                        }}
                      >
                        <span style={{ display: 'flex', columnGap: '20px', alignItems: 'center' }}>
                          <ClipboardDollarIcon color={iconColor} />
                          <Typography sx={menuOptTextStyle}>Finance</Typography>
                        </span>
                      </Link>
                      <Divider />
                    </>
                  )}
                </>
              ) : (
                <>
                  <Link
                    href={'/orders'}
                    sx={menuOptStyle}
                    onClick={() => {
                      trackNavigationMenu('Orders')
                    }}
                  >
                    <span style={{ display: 'flex', columnGap: '20px', alignItems: 'center' }}>
                      <BoxIcon />
                      <Typography sx={menuOptTextStyle}>Orders</Typography>
                    </span>
                  </Link>
                  <Divider />
                </>
              )}
              {!isEmployee && !isQorpak() && (
                <>
                  <Link
                    href="/invoices"
                    sx={menuOptStyle}
                    onClick={() => {
                      dispatch(reset())
                      trackNavigationMenu('Invoices')
                    }}
                  >
                    <span style={{ display: 'flex', columnGap: '20px', alignItems: 'center' }}>
                      <InvoiceIcon />
                      <Typography sx={menuOptTextStyle}>Invoices</Typography>
                    </span>
                  </Link>
                  <Divider />
                </>
              )}
              {shouldDisplayCatalog && (
                <>
                  <Link
                    href={catalogHref}
                    sx={menuOptStyle}
                    onClick={() => {
                      trackNavigationMenu('My Products')
                    }}
                  >
                    <span style={{ display: 'flex', columnGap: '20px', alignItems: 'center' }}>
                      <CatalogIcon />
                      <Typography sx={menuOptTextStyle}>My Products</Typography>
                    </span>
                  </Link>
                  <Divider />
                </>
              )}
              {!isEmployee && !isQorpak() && (
                <>
                  <Link
                    href="/notifications"
                    sx={menuOptStyle}
                    onClick={() => {
                      // Segment tracking
                      trackNavigationMenu('My Notifications')
                    }}
                  >
                    <span style={{ display: 'flex', columnGap: '20px', alignItems: 'center' }}>
                      <NotificationIcon />
                      <Typography sx={menuOptTextStyle}>My Notifications</Typography>
                    </span>
                  </Link>
                  <Divider />
                </>
              )}
              <Divider />
            </Box>
          )}
        </Box>
        {handleLogout ? (
          <Box
            sx={{
              display: 'flex',
              width: '100%',
              justifyContent: 'center',
              padding: '10px 24px',
            }}
          >
            <Button
              sx={{
                width: '100%',
                alignSelf: 'center',
                borderRadius: '999px',
                textTransform: 'none',
                fontSize: '16px',
                fontWeight: '600',
                justifyContent: 'center',
                paddingLeft: '24px',
                py: '12px',
                backgroundColor: isQorpak() ? theme.palette.common.primaryBlue : theme.palette.common.red,
                '&:hover': {
                  background: isQorpak() ? theme.palette.common.darkBlue : theme.palette.common.darkRed,
                },
              }}
              type="button"
              variant="contained"
              onClick={handleLogout}
            >
              Log Out
              <ArrowForwardIosIcon sx={{ width: '20px', height: '20px', marginLeft: '8px' }} />
            </Button>
          </Box>
        ) : (
          ''
        )}
      </Box>
    </Drawer>
  )
}

export default MobileMenu
